import React from 'react';

export const CartIcon = () => (
  <div style={{ width: `24px`, height: `24px`, lineHeight: 1 }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M23.663 6.49248C23.7449 6.20412 23.6822 5.8956 23.4934 5.65792C23.3046 5.42025 23.0113 5.28054 22.7 5.28H5.54699C5.42729 5.28132 5.32355 5.20068 5.29999 5.088L4.72799 2.4768C4.40663 1.03721 3.08461 0.00655545 1.55099 0C0.860639 0 0.300995 0.537258 0.300995 1.2C0.300995 1.86274 0.860639 2.4 1.55099 2.4C1.90642 2.40158 2.21216 2.64183 2.28399 2.976L5.45 17.4422C5.61417 18.1839 6.05386 18.8428 6.687 19.296C6.74773 19.3394 6.78458 19.407 6.78711 19.4797C6.78964 19.5524 6.75758 19.6222 6.7 19.6694C5.91398 20.3223 5.77994 21.4444 6.39159 22.2512C7.00323 23.058 8.15666 23.2805 9.04548 22.7632C9.93431 22.2459 10.2622 21.1613 9.79999 20.2675C9.76096 20.1933 9.76513 20.1048 9.811 20.0342C9.85702 19.9639 9.93744 19.9211 10.024 19.921H15.994C16.0805 19.921 16.1608 19.9639 16.2064 20.0344C16.252 20.105 16.2564 20.1931 16.218 20.2675C15.911 20.8624 15.9458 21.5672 16.3101 22.1315C16.6744 22.6958 17.3165 23.0393 18.008 23.04C18.7021 23.0396 19.3463 22.6939 19.7102 22.1264C20.074 21.559 20.1053 20.851 19.793 20.256C19.7551 20.1843 19.7587 20.0988 19.8026 20.0303C19.8464 19.9617 19.9242 19.92 20.008 19.92C20.6984 19.92 21.258 19.3827 21.258 18.72C21.258 18.0573 20.6984 17.52 20.008 17.52H8.63099C8.27685 17.519 7.9713 17.2812 7.897 16.9488L7.71799 16.128C7.70298 16.0574 7.72182 15.984 7.76931 15.9282C7.8168 15.8724 7.88791 15.84 7.963 15.84H18.717C20.0698 15.8398 21.2552 14.9704 21.611 13.7174L23.663 6.49248Z"
        fill="white"
      />
    </svg>
  </div>
);

export const DeleteIcon = () => (
  <div style={{ width: `1.25rem`, height: `1.25rem`, lineHeight: 1 }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 8.98999H4.5C4.22386 8.98999 4 9.21387 4 9.48999V21.99C4 23.0945 4.89543 23.99 6 23.99H18C19.1046 23.99 20 23.0945 20 21.99V9.48999C20 9.21387 19.7761 8.98999 19.5 8.98999ZM10.25 20.49C10.25 20.9043 9.91422 21.24 9.5 21.24C9.08578 21.24 8.75 20.9043 8.75 20.49V11.865C8.75 11.4507 9.08578 11.115 9.5 11.115C9.91422 11.115 10.25 11.4507 10.25 11.865V20.49ZM14.5 21.24C14.9142 21.24 15.25 20.9043 15.25 20.49V11.865C15.25 11.4507 14.9142 11.115 14.5 11.115C14.0858 11.115 13.75 11.4507 13.75 11.865V20.49C13.75 20.9043 14.0858 21.24 14.5 21.24Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8 3.78003C18.2276 3.88403 19.6243 4.24707 20.922 4.85107C21.5662 5.15308 21.9838 5.7937 22 6.50513C21.9918 7.05151 21.5465 7.48999 21 7.48999H3C2.73235 7.48999 2.47585 7.38281 2.2879 7.19214C2.09996 7.00171 1.99626 6.74365 2 6.47607C2.04474 5.73999 2.50218 5.09277 3.181 4.80493C4.44994 4.23657 5.80693 3.88989 7.19301 3.78003C7.73421 1.56665 9.71785 0.00952148 11.9965 0.00952148C14.2752 0.00952148 16.2588 1.56665 16.8 3.78003ZM14.6 3.5791C14.0975 2.59766 13.0794 1.98877 11.977 2.01001C10.8879 1.99438 9.88611 2.60425 9.40001 3.5791C11.1319 3.47803 12.8681 3.47803 14.6 3.5791Z"
        fill="#000"
      />
    </svg>
  </div>
);
