import React, { useEffect } from 'react';

import { remove, update } from './storage';

import { TitleColour, Bold, TextColour } from '../../utils/variables';

import { DeleteIcon } from './icons';

import loadComponent from '../Loadable';
// import loadLibrary from '../Loadable/library';
import { withCart } from './context';

// const Img = loadLibrary('gatsby-image');

const Button = loadComponent('button');
const ButtonHidden = loadComponent('button-hidden');
const Link = loadComponent('link');
// const Placeholder = loadComponent('product-placeholder');

export default withCart(function CartItems({
  items,
  total,
  count,
  updateTotal,
}) {
  useEffect(() => {
    function initItems() {
      updateTotal();
    }
    initItems();
  }, []);

  const updateProduct = (id, quantity) => {
    update(id, 'quantity', quantity);
    updateTotal();
  };

  const removeProduct = id => {
    remove(id);
    updateTotal();
  };

  const checkoutLabel = 'Proceed to Checkout';

  const itemName = val => {
    return val.charAt(0).toUpperCase() + val.slice(1);
  };

  return count > 0 ? (
    <>
      <p
        style={{
          ...headerStyle,
          paddingBottom: `1.25rem`,
          marginBottom: `1.25rem`,
          borderBottom: `1px solid rgba(0,0,0,0.1)`,
        }}
      >
        {count} Item{count > 1 ? 's' : ''} in Basket
      </p>
      <div style={totalStyle}>
        <span style={{ fontSize: `1.125rem` }}>Cart Subtotal</span>
        <span style={{ fontWeight: Bold }}>
          &pound;{parseFloat(Math.round(total * 100) / 100).toFixed(2)}
        </span>
      </div>
      <div style={actionsStyle}>
        <Button
          to="/checkout/"
          aria-label={checkoutLabel}
          style={{ width: `100%` }}
          primary
        >
          {checkoutLabel}
        </Button>
        <Link
          to="/basket/"
          content="View and Edit Basket"
          style={{
            textAlign: `center`,
            fontSize: `0.875rem`,
            marginTop: `0.9375rem`,
          }}
        />
      </div>
      {items
        .slice(0, 2)
        .reverse()
        .map((item, i) => {
          const cost = item.price * item.quantity;
          return (
            <div
              key={item.id}
              style={{
                ...singleProduct,
                marginTop: i !== 0 && `0.9375rem`,
              }}
            >
              {/* <div style={{ flex: `0 0 80px` }}>
              <Link to={item.link}>
                {item.featuredImage ? (
                  <Img
                    image={
                      item.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={item.name}
                  />
                ) : (
                  <Placeholder alt={item.name} />
                )}
              </Link>
            </div> */}
              <div style={infoStyle}>
                {/* <Link to={item.link}> */}
                <strong
                  style={{
                    fontSize: `1.125rem`,
                    color: TitleColour,
                  }}
                >
                  {itemName(item.reference || item.product)}
                </strong>
                <span style={{ fontSize: 12, color: TextColour }}>
                  H:{item.height}mm x W:{item.width}mm
                </span>
                {/* </Link> */}
                <div style={productActions}>
                  <p style={{ margin: 0 }}>
                    &pound;{parseFloat(Math.round(cost * 100) / 100).toFixed(2)}
                  </p>
                  <ButtonHidden
                    onClick={() => removeProduct(item.id)}
                    style={{
                      opacity: 0.4,
                      marginLeft: `auto`,
                      marginRight: `0.9375rem`,
                    }}
                  >
                    <DeleteIcon />
                  </ButtonHidden>
                  {/* <input
                    aria-label="Quantity"
                    type="number"
                    defaultValue={item.quantity}
                    min="1"
                    // max={item.stock_quantity}
                    onChange={e =>
                      e.target.value > 0 &&
                      updateProduct(item.id, e.target.value)
                    }
                    style={inputStyle}
                  /> */}
                </div>
              </div>
            </div>
          );
        })}
    </>
  ) : (
    <p style={headerStyle}>Your basket is empty</p>
  );
});

const headerStyle = {
  textAlign: `center`,
  fontWeight: Bold,
  color: TitleColour,
};

const totalStyle = {
  display: `flex`,
  width: `100%`,
  alignItems: `center`,
  justifyContent: `space-between`,
  marginBottom: `1.875rem`,
};

const actionsStyle = {
  paddingBottom: `1.25rem`,
  marginBottom: `1.875rem`,
  borderBottom: `1px solid rgba(0,0,0,0.1)`,
  display: `flex`,
  flexDirection: `column`,
};

const singleProduct = {
  display: `flex`,
};

const infoStyle = {
  flex: `auto`,
  paddingLeft: `0.9375rem`,
  display: `flex`,
  flexDirection: `column`,
};

const productActions = {
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
};

// const inputStyle = {
//   padding: `0.625rem`,
//   height: `42px`,
//   width: `42px`,
//   textAlign: `center`,
// };
